import { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import './App.css';

var requestedRegistrationToken = false;

const firebaseConfig = {
  apiKey: "AIzaSyBNfyNTz9BsCmm0BiRIItO0gAFp8MmPwBQ",
  authDomain: "breezy-dev-api.firebaseapp.com",
  projectId: "breezy-dev-api",
  storageBucket: "breezy-dev-api.appspot.com",
  messagingSenderId: "724859615067",
  appId: "1:724859615067:web:9f5b4457006fad7b32cc7e",
  measurementId: "G-LCTWLCD0HW"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
getAnalytics(app);

const registerNotification = (apiKey, token) => {
  return fetch(process.env.REACT_APP_API_URL + '/notifications', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api_key': apiKey,
    },
    body: JSON.stringify({ web_notification_token: token })
  })
}

function App() {
  const queryParams = new URLSearchParams(window.location.search)
  const apiKey = queryParams.get("key")
  const [message, setMessage] = useState('By allowing notifications, you authorize Breezy Development to send you notifications to this device. These notifications may include but are not limited to notifications related to the following: new lead alerts, marketing, account security')
  const [additionalMessage, setAdditionalMessage] = useState('')
  const [color, setColor] = useState('#FFE59F')
  const [registrationToken, setRegistrationToken] = useState(null)

  useEffect(() => {
    if (requestedRegistrationToken || !apiKey) return
    requestedRegistrationToken = true
    getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_PUB_KEY }).then((currentToken) => {
      if (!registrationToken && currentToken) {
        setRegistrationToken(currentToken)
        console.log('User granted permission. Registration Token: ' + currentToken)
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }, [])

  useEffect(() => {
    if (registrationToken && apiKey) {
      setMessage('Registering...')
      registerNotification(apiKey, registrationToken).then(() => {
        setMessage('You have successfully registered for notifications from Breezy Development')
        setAdditionalMessage('You may close this page now')
        setColor('green')
      }).catch(() => {
        setMessage('There was an error when registering you for notifications, contact the administrator for support')
        setAdditionalMessage('You may close this page now')
        setColor('red')
      })
    }
  }, [registrationToken, apiKey])

  if (!apiKey || apiKey.length === 0) {
    return (
      <div>
        Not Authenticated
      </div>
    )
  }

  return (
    <div className="App" style={{backgroundColor: color}}>
      <div className="message-container">
        <div className="message">
          {message}
        </div>
        <div className="additional-message">
          {additionalMessage}
        </div>
      </div>
    </div>
  );
}

export default App;
